import CreatePoint from 'src/components/adminPanel/CreatePoint';

const CreatePointPage = () => {
    return (
        <>
            <CreatePoint />
        </>
    );
};

export default CreatePointPage;
