import ManagePoints from '../../../components/adminPanel/ManagePoints';

const AdminPanel = () => {
    return (
        <>
            <ManagePoints />
        </>
    );
};

export default AdminPanel;
