import LogIn from 'src/components/adminPanel/LogIn';

const LogInPage = () => {
    return (
        <>
            <LogIn />
        </>
    );
};

export default LogInPage;
