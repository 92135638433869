import EditPoint from 'src/components/adminPanel/EditPoint';

const EditPointPage = () => {
    return (
        <>
            <EditPoint />
        </>
    );
};

export default EditPointPage;
