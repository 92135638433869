import Point from 'src/components/adminPanel/Point';

const PointPage = () => {
    return (
        <>
            <Point />
        </>
    );
};

export default PointPage;
